import styled from "styled-components";
import { deviceSizes } from "./deviceSizes";
import Link from "next/link";

export const H1 = styled.h1`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 44px;
  margin: 0px;
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;
  }
  & > b,h3,strong {
    font-weight: 500;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 72px;
    line-height: 78px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 96px;
    line-height: 110px;
  }
`;

export const H2 = styled.h2`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  margin: 0px;
  & > ul {
    padding-inline-start: 30px;
  }
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;

  }
  & > b,h3,strong {
    font-weight: 500;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 40px;
    line-height: 48px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 52px;
    line-height: 64px;
  }
`;

export const H3 = styled.h3`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;
  }
  & > b,h3,strong {
    font-weight: 500;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 28px;
    line-height: 36px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const MobileNavItem = styled(Link)`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;
  }
  & > b,h3,strong {
    font-weight: 500;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 28px;
    line-height: 36px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const NavItem = styled(Link)`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;
  }
  & > b,h3,strong {
    font-weight: 500;
  }
  & > ul {
    padding-inline-start: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ContinuousText = styled.div`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  & > i {
    font-family: "Tiempos Headline", sans-serif;
    font-style: normal;
  }
  & > b,h3,strong {
    font-weight: 500;
  }
  & > ul {
    padding-inline-start: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ButtonText = styled.div`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  & > b,h3,strong {
    font-weight: 500;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
