import styled from "styled-components";
import { deviceSizes } from "./deviceSizes";
import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";

export const GlobalStyle = createGlobalStyle`
body {
  padding: 0;
  margin: 0;
  background: ${({ customColor }) => (customColor ? customColor : colors.weis)};
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
.grecaptcha-badge {
  visibility: hidden;
}
`;

export const MainGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  overflow: hidden;
  padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 20px")};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-template-columns: repeat(12, 1fr);
    padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 25px")};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
    padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 25px")};
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    justify-content: center;
    grid-template-columns: repeat(12, 90px);
    padding: 0px;
  }
`;
