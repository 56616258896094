export const colors = {
  anthrazit: "#1a1a1a",
  weis: "#ffffff",
  akzentGrun: "#3A9E74",
  uberUnsBeige: "#EFE0D7",
  uberUnsBackground: "#FFF8F4",
  textBoxBeige: "#F4EFEC",
  leistungenGrau: "#FAF7F6",
  textInactive: "#9E9995",
  buttonHoverGrun: "#3E896A",
  errorRot: "#F24E1B",
  transparent: "rgba(0, 0, 0, 0)",
  background: "#F8F8F8",
};
