import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";
import { ContinuousText } from "../../../styles/Text.styles";

export const MainWrapper = styled.div`
  position: fixed;
  bottom: -150px;
  transform: translateY(${({ cookies }) => (cookies ? "0px" : "-155px")});
  z-index: 2;
  margin: 0px 5px;
  width: calc(100% - 10px);
  border-radius: 8px;
  background: ${colors.anthrazit};
  transition: 0.15s ease-in-out;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: calc(100% - 50px);
    margin: 0px 25px;
    transform: translateY(${({ cookies }) => (cookies ? "0px" : "-175px")});
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: calc(100% - 92px);
    margin: 0px 46px;
    transform: translateY(${({ cookies }) => (cookies ? "0px" : "-196px")});
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
  color: ${colors.weis};
  gap: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: 23px 30px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    margin: 23px 60px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.weis};
  gap: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    gap: 60px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 50px;
  }
`;

export const Button = styled(ContinuousText)`
  cursor: pointer;
`;

export const InlineButton = styled(ContinuousText)`
  display: inline;
  margin-left: 3px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

