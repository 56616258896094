import { ContinuousText } from "../../../styles/Text.styles";
import {
  ContentWrapper,
  MainWrapper,
  ButtonWrapper,
  Button,
  InlineButton,
} from "./CookieDialog.styles";
import Link from "next/link";

export const CookieDialog = ({ setCookies, cookies }) => {
  return (
    <MainWrapper cookies={cookies}>
      <ContentWrapper>
        <ContinuousText>
          Wir verwenden Cookies, weitere Hinweise findest du in unserer
          <Link href="privacy" prefetch={false}>
            <InlineButton>Datenschutzerklärung</InlineButton>
          </Link>
        </ContinuousText>
        <ButtonWrapper>
          <Button onClick={() => setCookies(true)}>
            <b>Zustimmen</b>
          </Button>
          <Button>Cookie Einstellungen</Button>
        </ButtonWrapper>
      </ContentWrapper>
    </MainWrapper>
  );
};
