import { CookieDialog } from "../components/common/CookieDialog/CookieDialog.component";
import { useState } from "react";
import "../public/globals.css";
import { GlobalStyle } from "../styles/Main.styles";

const Main = ({ Component, pageProps }) => {
  const [cookies, setCookies] = useState(true);
  return (
    <>
      <GlobalStyle />
      <Component {...pageProps} />
      <CookieDialog setCookies={setCookies} cookies={cookies} />
    </>
  );
};

export default Main;
